body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* three mens morris board */
.board {
  &.disabled {
    pointer-events: none;
    .row {
      .cell {
        pointer-events: none;
        background-color: #ccc;
      }
    }
  }
  width: 300px;
  height: 300px;

  // align center
  margin: 0 auto;
  margin-top: 50px;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .cell {
      width: 100px;
      height: 100px;
      border: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      cursor: pointer;
      background-color: bisque;

      &.selected {
        background-color: #f0f0f0;
      }
    }
  }
}

.select-menu {
  width: 300px;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  select {
    width: 200px;
    height: 30px;
    font-size: 20px;
  }
  label {
    font-size: 20px;
  }
}

.game-button {
  width: 300px;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100px;
    height: 30px;
    font-size: 20px;
    background-color: orange;
  }
}
